<template>
  <v-dialog v-model="show" max-width="500" persistent>
    <v-card>
      <v-container>
        <OrganizzeModalHeader
          :loading="modal.loading"
          :isDelete="true"
          :title="modal.title"
          @onClose="closeModal()"
        />

        <v-row style="justify-content: center;">
          <v-col cols="12" sm="8" md="8" style="text-align: center;">
            <h4>{{`Tem certeza que deseja excluir o lançamento ${record.description}?`}}</h4>
          </v-col>

          <v-col cols="12" sm="8" md="8" style="text-align: center;" class="mb-0 pb-0">
            <v-btn
              class="br-btn"
              color="secondary"
              style="width: 100%;"
              :loading="modal.loading"
              @click="excluirConta()">
              Confirmar
            </v-btn>
          </v-col>

          <v-col cols="12" sm="8" md="8" style="text-align: center;" class="mb-0 pb-0">
            <v-btn
              class="br-btn"
              color="secondary"
              style="width: 100%;"
              :loading="modal.loading"
              v-if="allDeleted"
              @click="excluirTodosLancamentos()">
              Deletar todos lancamentos
            </v-btn>
          </v-col>

          <v-col cols="12" sm="8" md="8" style="text-align: center;">
            <v-btn
              class="br-btn"
              color="error"
              style="width: 100%;"
              :loading="modal.loading"
              @click="closeModal()">
              Cancelar
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import Events from '@/core/service/events'
import { mapActions } from 'vuex'
import { errorSnackbar, successSnackbar } from '@/core/service/utils'

const namespaceStore = 'organizzeFinanceiroLancamentos'

export default {
  name: 'OrganizzeFinanceiroLançamentosModalExcluir',

  components: {
    OrganizzeModalHeader: () => import('@/views/components/organizze/modal/header')
  },
  props:['show'],
  data: (() => ({
    modal: {
      toggle: false,
      loading: false,
      title: ''
    },
    record: {},
    allDeleted: false
  })),

  mounted () {
    const self = this
    Events.$on('modalCadastro::edit', data => {
      self.record = {}
      self.allDeleted = false
      self.modal.toggle = true
      self.modal.title = data.record.description
      self.record = data.record
      self.allDeleted = data.record.jsonFlags && data.record.jsonFlags.parentId ? data.record.jsonFlags.parentId : false
    })
  },

  methods: {
    ...mapActions(namespaceStore, ['deleteRecord']),

    closeModal () {
      const self = this
      self.modal = {
        toggle: false,
        loading: false
      }
      self.record = {}
      Events.$emit('close::modal::categoria')
    },

    excluirConta () {
      const self = this
      self.modal.loading = true
      self.deleteRecord({ id: self.record.id }).then(() => {
        successSnackbar('Lançamento excluído com sucesso.')
        Events.$emit('OrganizzeFilter::buscar')
        self.closeModal()
      }).catch(e => {
        console.error(e)
        errorSnackbar(e)
      }).finally(() => {
        self.modal.loading = false
      })
    },
    excluirTodosLancamentos () {
      const self = this
      let { record } = self
      self.modal.loading = true
      self.deleteRecord({ id: record.id, dtEmission: record.dtEmission, parentId: record.jsonFlags.parentId || false }).then(() => {
        successSnackbar('Lançamento excluído com sucesso.')
        Events.$emit('OrganizzeFilter::buscar')
        self.closeModal()
      }).catch(e => {
        console.error(e)
        errorSnackbar(e)
      }).finally(() => {
        self.modal.loading = false
        Events.$emit('categorias::update::tables')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '~@/assets/styles/helpers/_variables.scss';

  .v-card { border-radius: 16px !important; }

  .v-btn { text-transform: uppercase; }
</style>